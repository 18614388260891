<template>
  <div class="container-fluid">
    <div id="two-column-menu"></div>

    <template v-if="layoutType === 'twocolumn'">
      <SimpleBar class="navbar-nav" id="navbar-nav"></SimpleBar>
    </template>

    <template v-else>
      <ul class="navbar-nav" id="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/">
            <i class="icon-style ri-home-8-line"></i>
            <span data-key="t-widgets">Accueil</span>
          </router-link>
        </li>

        <li class="nav-item" v-if="user.role == 'admin'">
          <router-link class="nav-link menu-link" to="/new-student">
            <i class="icon-style  ri-user-3-line "></i>
            <span data-key="t-apps">Elèves</span>
          </router-link>
        </li>

        <li class="nav-item" v-if="user.role == 'admin'">
          <router-link class="nav-link menu-link" to="/new-filiere">
            <i class="icon-style  las la-school"></i>
            <span data-key="t-apps">Classes</span>
          </router-link>
        </li>

        <li class="nav-item" v-if="user.role == 'admin' || user.role == 'enseignant'">
          <a class="nav-link menu-link" href="#sidebarAttendance" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarAttendance">
            <i class="icon-style  ri-task-fill"></i>
            <span data-key="t-pages">Gestion de Présences</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarAttendance">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/courses/presence" class="nav-link" data-key="t-team">
                  Présences
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/courses/journal_presence" class="nav-link" data-key="t-team">
                  Journal de presences
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" v-if="user.role == 'admin' || user.role == 'enseignant'">

          <a class="nav-link menu-link" href="#sidebarNotes" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarNotes">
            <i class="icon-style ri-pencil-line"></i>
            <span data-key="t-pages">Gestion de Notes</span>
          </a>
          <div class="collapse menu-dropdown" id="sidebarNotes">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item">
                <router-link to="/courses/notes" class="nav-link" data-key="t-team">
                  Reporter
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/courses/releves" class="nav-link" data-key="t-team">
                  Relevés
                </router-link>
              </li>
            </ul>
          </div>
        </li>


        <li class="nav-item" v-if="user.role == 'admin'">
          <a class="nav-link menu-link collapsed" href="#sidebarMultilevel" data-bs-toggle="collapse" role="button"
            aria-expanded="false" aria-controls="sidebarMultilevel">
            <i class="mdi mdi-cog-outline"></i>
            <span data-key="t-multi-level">Paramètres</span>
          </a>

          <div class="menu-dropdown collapse" id="sidebarMultilevel" style="">
            <ul class="nav nav-sm flex-column">
              <li class="nav-item" v-if="user.role == 'admin'">
                <router-link class="nav-link" to="/new-teacher">
                  <span data-key="t-base-ui">Enseignants</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/ecolages" class="nav-link menu-link" data-key="t-fees">
                  Ecolages
                </router-link>
              </li>
              

              <li class="nav-item">
                <a class="nav-link" href="#sidebarAccount" data-bs-toggle="collapse" role="button" aria-expanded="false"
                  aria-controls="sidebarAccount" data-key="t-level-1.2">Cours</a>
                <div class="collapse menu-dropdown" id="sidebarAccount">
                  <ul class="nav nav-sm flex-column">
                    <li class="nav-item">
                      <router-link to="/courses/programmer" class="nav-link" data-key="t-team">
                        Programmes
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/courses/planifier" class="nav-link" data-key="t-team">
                        Emploi du temps
                      </router-link>
                    </li>

                  </ul>
                </div>
              </li>
              <!-- <li class="nav-item" v-if="user.role == 'admin'">
                <router-link class="nav-link menu-link" to="/parametrer-annee">
                  <span data-key="t-pages">Année scholaire</span>
                </router-link>
              </li> -->
            </ul>
          </div>

        </li>

        <li class="nav-item" v-if="user.role == 'tutor'">
          <router-link class="nav-link menu-link" to="/tutor/students">
            <i class="icon-style ri-user-2-line"></i>
            <span data-key="t-base-ui">Elèves</span>
          </router-link>
        </li>
      </ul>
    </template>
  </div>
</template>

<style scoped>
@keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
</style>

<script>
import { layoutComputed } from "@/state/helpers";
import { SimpleBar } from "simplebar-vue3";
import { mapGetters } from "vuex";

export default {
  components: { SimpleBar },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
      user: {},
    };
  },
  computed: {
    ...mapGetters(['getUserState', 'getloggedUser']),
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {};
      },
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getUser() {
      try {
        const response = await this.$store.dispatch('getRequest', { route: 'api/loggedUser' });
        this.user = response.data.user;
        await this.$store.dispatch('setLoggedUser', { user: this.user });
      } catch (error) {
        console.log(error);
      }
    },

    onRoutechange(ele) {
      this.initActiveMenu(ele.path);
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
            document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop =
                currentPosition + 300;
        }
      }, 300);
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
            .querySelector("#navbar-nav")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (
                parentCollapseDiv.parentElement.closest(
                  ".collapse.menu-dropdown"
                )
              ) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },

  mounted() {
    this.getUser();
    // console.log('getUserState = '+this.getUserState);
    // console.log(sessionStorage.getItem('authenticated'));
    setTimeout(() => {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {
              let siblingCollapses =
                closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  siblingCollapse.classList.remove("show");
                }
              });
            } else {
              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });
            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    }, 0);
  },

};
</script>
